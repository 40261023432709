import * as React from "react"

import Layout from "@components/layout"
import Seo from "@components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import "@styles/pages/contact.scss"

const Contact = () => (
  <Layout darkMode={true} disableFooter={true} disableSticky={true}>
    <Seo title="Contact Us" />
    <div className="container-new contact-wrap" style={{ overflow: "hidden" }}>
      <div className="row h-100">
        <div className="col-12 col-md-5">
          <div className="d-flex justify-content-end flex-column h-100">
            <p className="font-35">
              7509 Girard Ave <br />
              La Jolla, CA
              <br />
              92037
              <br />
              <a className="contact-phone" href="tel:+18584546909">
                858-454-6909
              </a>
              <br />
              <br />
              <a
                href="mailto:inquire@sixteenfifty.com"
                className="contact-email"
              >
                inquire@
                <br />
                sixteenfifty.com
              </a>
            </p>
            <div className="font-35">
              <Link to="/jobs/" className="contact-careers">
                1650 Careers
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-7 relative img-bg">
          <StaticImage
            src="https://wordpress-742363-2492908.cloudwaysapps.com/wp-content/uploads/2022/03/Sixteen_Building.png"
            placeholder="none"
          />
        </div>
      </div>
    </div>
  </Layout>
)

export default Contact
